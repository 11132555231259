import React, {useState} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { GatsbyImage } from "gatsby-plugin-image";
import InnerLinkButton from '../../components/svgs/buttons/InnerLinkButton';
import Modal from './modal';
import {Link} from 'gatsby';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    textAlign: 'center',
    '& h2': {
      marginBottom: 30
    },
    '& p': {
      marginTop: -20,
      marginBottom: 30,
    },
    '& h3': {
      margin: '30px 0'
    },
    '& .gatsby-image-wrapper': {
      width: '100%'
    }
  },
  os: {
    '& .gatsby-image-wrapper': {
      height: 90,
      [theme.breakpoints.down('xs')]: {
        height: 60,
      },
      '& img': {
        objectFit: 'contain!important'
      }
    }
  },
  item: {
    position: 'relative'
  }
}));

const List = (props) => {
  const [active, setActive] = useState('');

  const renderListItems = () => {
    return props.items.map((item, index) => {
      const handleButtonClick = () => {
        setActive(index);
      }
      const handleClose = () => {
        setActive('')
      }
      return (
        props.noDetails ? 
        <Grid className={classes.os} item key={index} xs={4}>
          <GatsbyImage image={item.node.logo.gatsbyImageData} alt=""/>
        </Grid> : 
        <Grid className={classes.item} item container key={index} xs={12} sm={4} direction='column' alignItems='center'>
          <GatsbyImage image={item.node.image.gatsbyImageData} alt=""/>
          <h3>{item.node.name}</h3>
          <InnerLinkButton plus text={props.lang === 'en-CA' ? 'Show more' : 'En savoir plus'} onClick={handleButtonClick}/>
          <Modal lang={props.lang} device={item.node} index={index} active={active} onClick={handleClose}/>
        </Grid>  
      );
    });
  }

  const classes = useStyles();
  return (
    <>
      <section id={props.id} className={classes.wrapper}>
        <Container>
          <Grid container direction='column' alignItems='center'>
            <h2>{props.title}</h2>
            {props.subtitle ? <p>{props.subtitle}</p> : ''}
            {props.items  ? '' : <Link to={props.lang === 'en-CA' ? '/en/contact-us' : '/nous-parler'}><InnerLinkButton text={props.lang === 'en-CA' ? 'Request a quote' : 'Demandez une soumission'} /></Link>}
            <Grid item container md={props.noDetails ? 6 : 12} spacing={5}>
              {props.items ? renderListItems() : ''}
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
}

export default List;