import React from 'react';
import HomeContentSection from '../Home/HomeContentSection/';
import List from './list';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import {MagnetoLito} from '../../components/svgs/magneto-lito';

const useStyles = makeStyles((theme) => ({
  banner: {
    background: 'linear-gradient(to left, rgba(255,255,0,1) 0%,rgba(255,181,0,1) 100%)',
    marginTop: -60,
    padding: '90px 0!important',
    [theme.breakpoints.down('xs')]: {
      padding: '45px 0!important',
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      '& > svg': {
        width: 200,
        marginRight: 30,
        [theme.breakpoints.down('sm')]: {
          display: 'none'
        },
      },
      '& h2': {
        marginTop: 20
      }
    }
  },
}));

const Hardware = (props) => {
  const classes = useStyles();
  return (
    <>
      <section className={classes.banner + ' clipped'}>
        <Container>
          <MagnetoLito/><h2>{props.lang === 'en-CA' ? 'No matter where you are in Canada, we have installers for you!' : 'Un réseau d\'installateurs qui s\'étend partout au Canada!'}</h2>
        </Container>
      </section>
      <List noDetails items={props.os} lang={props.lang} title={props.lang === 'en-CA' ? 'We develop on these platforms' : 'Nous développons sur ces plateformes'}/>
      <List id='screens' items={props.smartTv} lang={props.lang} title={props.lang === 'en-CA' ? 'You have no screen?' : 'Vous n\'avez pas d\'écran?'} subtitle={props.lang === 'en-CA' ? 'Here are our recommendations' : 'Voici nos recommandations'}/>
      <List id='players' items={props.miniPc} lang={props.lang} title={props.lang === 'en-CA' ? 'You have a screen?' : 'Vous avez un écran?'} subtitle={props.lang === 'en-CA' ? 'Here are our recommendations' : 'Voici nos recommandations'}/>
      <List id='videowalls-and-touchscreens' lang={props.lang} title={props.lang === 'en-CA' ? 'Touch project or videowall?' : 'Projet tactile ou mur vidéo?'} subtitle={props.lang === 'en-CA' ? 'Contact us so that we can suggest the best equipment for you!' : 'Contactez-nous directement afin qu\'on vous suggère le meilleur équipement!'}/>
      <HomeContentSection lang={props.lang}/>
    </>
  );
}

export default Hardware;