import React from 'react';
import {colors} from '../../../style/constants';
import InnerLinkButton from '../../../components/svgs/buttons/InnerLinkButton';
import { Link } from "gatsby";
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {graphql, useStaticQuery} from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: colors.ATT_BLUE,
    '& p': {
      margin: '30px 0'
    },
    '& h2, p': {
      paddingRight: 60,
      color: colors.WHITE
    },
    '& .gatsby-image-wrapper': {
      width: '100%',
    },
    '& a': {
      marginRight: 30,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 15,
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& > div > div': {
        flexFlow: 'column-reverse',
        '& > :last-child': {
          justifyContent: 'flex-start',
          marginBottom: 30,
        }
      }
    }
  },
}));

const HomeContentSection = (props) => {
  const data = useStaticQuery(graphql`
    query contentImage {
      image: file(relativePath: { eq: "home/Attractif-Content.png"}) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const classes = useStyles();
  return (
    <section className={classes.wrapper + " clipped"}>
      <Container id="home-content-manager">
        <Grid container alignItems='center'>
          <Grid item sm={12} md={6}>
            <h2>{props.lang === 'en-CA' ? 'We are content driven' : 'Nous sommes axés sur le contenu'}</h2>
            <p>{props.lang === 'en-CA' ? 'Use our designed and animated templates that you can customize in seconds or save time with our turnkey content.' : 'Personnalisez facilement nos gabarits infographiés et animés. Épargnez du temps avec du contenu clé en main.'}</p>
            {props.lang === 'en-CA' ? <Grid container><Link to="/en/content#templates"><InnerLinkButton text="Templates" /></Link><Link to="/en/content##turnkey-content"><InnerLinkButton text="Turnkey content" /></Link></Grid> : <Grid container><Link to="/contenu#templates"><InnerLinkButton text="Gabarits" /></Link><Link to="/contenu#turnkey-content"><InnerLinkButton text="Clé en main" /></Link></Grid>}
          </Grid>
          <Grid container justifyContent="flex-end" item sm={12} md={6}>
            <GatsbyImage image={data.image.childImageSharp.gatsbyImageData}  alt="Content driven - Axés sur le contenu"/>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default HomeContentSection;