import React from "react";
import Seo from "../components/seo";
import Layout from "../components/layout";
import Hardware from '../layouts/Hardware'; 
import {graphql, useStaticQuery} from 'gatsby';

const HardwarePageFr = () => {

  const data = useStaticQuery(graphql`
    query HardwareDatasFr {
      allContentfulOs(filter: {node_locale: {eq: "fr-CA"}}, sort: {fields: name, order: DESC}) {
        edges {
          node {
            name
            logo {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
      allContentfulSmartTv(sort: {order: ASC, fields: cost}, filter: {node_locale: {eq: "fr-CA"}}) {
        edges {
          node {
            name
            image {
              gatsbyImageData(layout: CONSTRAINED)
            }
            performance
            quality
            stability
            cost
            features
          }
        }
      }

      allContentfulMiniPc(sort: {order: ASC, fields: cost}, filter: {node_locale: {eq: "fr-CA"}}) {
        edges {
          node {
            name
            image {
              gatsbyImageData(layout: CONSTRAINED)
            }
            performance
            stability
            cost
            features
          }
        }
      }    
    }
  `)

  return (
    <Layout 
    lang="fr-CA" 
    switchLink='/en/hardware' 
    slider={false} 
    title='affichage dynamique'
    subTitle={'L\'équipement que vous cherchez pour votre'}>
      <Seo 
        title="Écrans, ordinateurs, tableaux et boîtiers d'affichage dynamique"
        description="Que vous souhaitiez une installation complète ou que vos équipements d'affichage actuels soient mis à niveau, Attractif vous accompagne dans la transformation numérique de votre affichage."
       />
      <Hardware lang='fr-CA' os={data.allContentfulOs.edges} smartTv={data.allContentfulSmartTv.edges} miniPc={data.allContentfulMiniPc.edges}/>
    </Layout>
  )
}

export default HardwarePageFr
