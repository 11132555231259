import React from "react";

const Plus = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.79 32.79">
      <circle cx="16.4" cy="16.4" r="15.4" fill="none" stroke={props.color} strokeMiterlimit="10" strokeWidth="2px"/>
      <line x1="16.29" y1="24.47" x2="16.29" y2="8.32" fill="none" stroke={props.color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px"/>
      <line x1="24.37" y1="16.4" x2="8.22" y2="16.4" fill="none" stroke={props.color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px"/>
    </svg>
  );
}

export default Plus;
