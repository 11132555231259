import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Plus from '../../components/svgs/plus';
import {colors} from '../../style/constants';

const useStyles = makeStyles((theme) => ({
  active: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    background: 'rgba(255,255,255,0.97)',
    display: 'flex',
    padding: 40,
    boxSizing: 'border-box',
    textAlign: 'left',
    '& h5': {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 10,
      '& span': {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
          height: '1.25em',
          marginLeft: 10,
        }
      }
    },
    '& ul': {
      borderTop: colors.TURQUOISE + ' 2px solid',
      paddingTop: 20,
      marginTop: 20,
      flexWrap: 'wrap',
      display: 'flex',
      '& li': {
        marginRight: 15,
        marginBottom: 5
      }
    },
    '& button': {
      width: 35,
      height: 35,
      padding: 0,
      borderRadius: '50%',
      marginTop: 15,
      backgroundColor: 'transparent',
      '& svg': {
        width: '100%',
        transform: 'rotate(45deg)'
      }
    }
  },
  inactive: {
    display: 'none',
    zIndex: -1
  },
}));

const Modal = (props) => {
  const renderFeatures = () => {
    return props.device.features.map((feature, index) => {
      return (
        <li key={index}>{feature}</li>
      );
    });
  }

  const renderRating = (value) => {    
    let ratingColor
    if (value === 1) {
      ratingColor = [colors.TURQUOISE, colors.GREY, colors.GREY, colors.GREY, colors.GREY] 
    }
    if (value === 2) {
      ratingColor = [colors.TURQUOISE, colors.TURQUOISE, colors.GREY, colors.GREY, colors.GREY] 
    }
    if (value === 3) {
      ratingColor = [colors.TURQUOISE, colors.TURQUOISE, colors.TURQUOISE, colors.GREY, colors.GREY] 
    }
    if (value === 4) {
      ratingColor = [colors.TURQUOISE, colors.TURQUOISE, colors.TURQUOISE, colors.TURQUOISE, colors.GREY] 
    }
    if (value === 5) {
      ratingColor = [colors.TURQUOISE, colors.TURQUOISE, colors.TURQUOISE, colors.TURQUOISE, colors.TURQUOISE] 
    }
    return (
      <>
        <Plus color={ratingColor[0]}/>
        <Plus color={ratingColor[1]}/>
        <Plus color={ratingColor[2]}/>
        <Plus color={ratingColor[3]}/>
        <Plus color={ratingColor[4]}/>
      </>
    )
  }

  const handleClosingClick = () => {
    props.onClick();
  }

  const classes = useStyles();
  return (
    <div className={props.index === props.active ? classes.active : classes.inactive}>
      <Grid container>
        <Grid item xs={12}>
          <h5>Performance <span>{renderRating(props.device.performance)}</span></h5>
          <h5>{props.lang === 'en-CA' ? 'Stability' : 'Stabilité'} <span>{renderRating(props.device.stability)}</span></h5>
          {props.device.quality ? <h5>{props.lang === 'en-CA' ? 'Image quality' : 'Qualité d\'image'} <span>{renderRating(props.device.quality)}</span></h5> : ""}
          <h5>{props.lang === 'en-CA' ? 'Cost' : 'Coût'} <span>{renderRating(props.device.cost)}</span></h5>
          <ul>
            {renderFeatures()}
          </ul>
          <Grid container justifyContent='center'>
            <button onClick={handleClosingClick}>
              <Plus color={colors.TURQUOISE}/>
            </button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Modal;